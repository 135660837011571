// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-print-page-js": () => import("/opt/build/repo/src/templates/print-page.js" /* webpackChunkName: "component---src-templates-print-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merch-index-js": () => import("/opt/build/repo/src/pages/merch/index.js" /* webpackChunkName: "component---src-pages-merch-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-prints-index-js": () => import("/opt/build/repo/src/pages/prints/index.js" /* webpackChunkName: "component---src-pages-prints-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

